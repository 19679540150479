/* src/index.css */
html {
  scroll-behavior: smooth;
  width: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

#root {
  width: 100%; /* Ensure it takes the full width of the screen */
  margin: 0 auto;
  overflow-x: hidden; /* Prevent any element from going out of the viewport */
}

section {
  width: 100%; /* Make sure the sections take up 100% of the screen */
  box-sizing: border-box; /* Ensures padding and borders are included in the width */
}

@media (max-width: 768px) {
  body {
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }

  /* Removed the width limitation for #root */
  #root {
    width: 100%; /* Make sure it occupies full width on small screens */
    margin: 0 auto;
  }

  /* Hamburger menu styles */
  .hamburger {
    display: block;
    cursor: pointer;
  }

  .menu {
    display: none;
  }

  .menu.open {
    display: block;
  }

  .App {
    width: 100%;  /* Ensure the app uses the full width */
    margin: 0;
    background-color: #fff;
  }

  section {
    width: 100%;  /* Ensure sections take up the full width */
  }
}
